import React from "react"
import ListItem from "./listItem"

const education = [
  {
    name: "Cocomelon - Nursery Rhymes",
    subs: "51.6M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l79wZ6qBUvS5bcIe_XVWu7cUdHEEZRUnK18Pcg=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCbCmjCuTUZos6Inko4u57UQ",
    rank: 1,
  },
  {
    name: "Billion Surprise Toys - BST Nursery Rhymes",
    subs: "24.1M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l79jsgXPb0e4zxr_0LnMBZ0dwlIQhUWOauf16A=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCRx3mKNUdl8QE06nEug7p6Q",
    rank: 2,
  },
  {
    name: "ChuChu TV Nursery Rhymes & Kids Songs",
    subs: "24M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l78wW7ps8LDRPei5ZmJJYzlNOkWY6_aVN07WmA=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCBnZ16ahKA2DZ_T5W0FPUXg",
    rank: 3,
  },
  {
    name: "CVS 3D Rhymes",
    subs: "20M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l78cDw82-VDhuZ0N_BB5R16J_8nOssRwFKaNZQ=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCsPF3cApzCohxPp5oKdoWSQ",
    rank: 4,
  },
  {
    name: "Pinkfong! Kids' Songs & Stories",
    subs: "20M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7-3POnXYv9WeLeJBKZAHdN2qZms-ZbQvTuSRQ=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCcdwLMPsaU2ezNSJU1nFoBQ",
    rank: 5,
  },
  {
    name: "Little Baby Bum - Nursery Rhymes & Kids Songs",
    subs: "18.7M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7_8t8wY2pMh6Va3Guv5Qj7EIqVHPU7I9bOT9g=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCKAqou7V9FAWXpZd9xtOg3Q",
    rank: 6,
  },
  {
    name: "Super Simple Songs - Kids Songs",
    subs: "15.2M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7-GxGBclr37KtPwF0nDVovSYgW751mVqJ62rQ=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCLsooMJoIpl_7ux2jvdPB-Q",
    rank: 7,
  },
  {
    name: "Infobells - Hindi",
    subs: "14.8M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7_nLXrAC1uwDihhJ2gIsBMCukRppakhbmy-cw=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UC1ciY6kR3yj3kaKZ6R7ewAg",
    rank: 8,
  },
  {
    name: "Toys and Colors",
    subs: "13.6M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7-3MPAlUOCiMTctlWwDpc7Q82qij22J5ZUjbA=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCgFXm4TI8htWmCyJ6cVPG_A",
    rank: 9,
  },
  {
    name: "Kids TV - Nursery Rhymes And Baby Songs",
    subs: "13M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7-eRsuCOQ43Hj9WVVU1xGY2L6sgA8S1DZLM2w=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UC7Pq3Ko42YpkCB_Q4E981jw",
    rank: 10,
  },
  {
    name: "The King of Random",
    subs: "11.4M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7903sDCe8kkmFFSKhznQOho6fTemCuw_f3zHg=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/user/01032010814",
    rank: 11,
  },
  {
    name: "ToyMonster",
    subs: "9.8M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l796-zoDXcE6dEEElPmbbEZFy8v0UFbRUe7xcg=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCLGOpkME9pyo3gDzWWrJwqA",
    rank: 12,
  },
  {
    name: "CrashCourse",
    subs: "9.4M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l785JsRUb9Bf3NT_Y2yIla9xx4Y3F2nxEwXU0g=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCX6b17PVsYBQ0ip5gyeme-Q",
    rank: 13,
  },
  {
    name: "TED-Ed",
    subs: "9.3M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7_KYzscxJEPD222JCwLBSiMIDXi5wtsWc4Rww=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCsooa4yRKGN_zEE8iknghZA",
    rank: 14,
  },
  {
    name: "Kurzgesagt – In a Nutshell",
    subs: "9M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l79_o6QngIpSSvxjU7AnZu86z3_7OzCy-wn2Bw=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCsXVk37bltHxD1rDPwtNM8Q",
    rank: 15,
  },
  {
    name: "Funny Stop Motion videos",
    subs: "8.7M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7-fiEyQ_dI6RX-J0GFFWTVVbP1Gg62frodR_Q=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/user/UnboxingSurpriseEgg",
    rank: 16,
  },
  {
    name: "Dr. Vivek Bindra: Motivational Speaker",
    subs: "8.7M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l79aybSS7Frrrd1jCmoVwaR7-ryhWdt7WoepCw=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCR-foyF-C6VuAlwy3KZMkgA",
    rank: 17,
  },
  {
    name: "wifistudy",
    subs: "8.5M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7-pt9dHmqvF16KbbU0XvYKkKHcTOxX5AkZLxA=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCuWuAvEnKWez5BUr29VpwqA",
    rank: 18,
  },

  {
    name: "Little Baby Bum en Español",
    subs: "8.2M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l79vM2lUXXd53hvL4ATLraA-XMJDNofa34DpeA=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCHicabXz9rUMWLcdMqBtbxQ",
    rank: 19,
  },
  {
    name: "El Reino a Jugar",
    subs: "8M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7_bi4TxQa9r-yhReE3jGGuSb0R3PF3xKDI4OQ=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCsm77kEHBDEPgD4Ir3jSRYg",
    rank: 20,
  },
  {
    name: "FunToysMedia",
    subs: "7.8M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7-TjQEeD7Ik0Usa6F2IudL7y0BfXuezZYp0Qg=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCjmAh2qxmvWdH3xNPbg0IUQ",
    rank: 21,
  },
  {
    name: "infobells - Telugu",
    subs: "7.7M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l79Tiyj_81lnhmvbYCxhjKG-7oXUz1abhva6PA=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCHvoq_rGmOKJKGId1nD1V4Q",
    rank: 22,
  },
  {
    name: "Videogyan 3D Rhymes - Nursery Rhymes & Kids Songs",
    subs: "7.6M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7-Bz97ph1bK8xcnhITfUodZ7XcPdxqR_QQwMQ=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCs0upBDG-dCAxy8_VDPE5XA",
    rank: 23,
  },
  {
    name: "TsMadaan",
    subs: "7.4M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7-TdvePED_6Yh8s-up9-AFPhrQTfkZG21bzjw=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCKjJXmZxk1SiTUQUB28KHpw",
    rank: 24,
  },
  {
    name: "YtCrash",
    subs: "7.3M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l797ejjpEBw8X7uEaztcGt6GWjXKN4uVBVxVpg=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCkb0yUW1GGo6sHaJ2Z9IIrA",
    rank: 25,
  },
  {
    name: "Alena Kids Toys",
    subs: "7.3M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7_rR3tb4-wbn1d4HQwU4oNg2-3htaPnDV72cw=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCpIAHQcBQp5yWGQ7UR3xixg",
    rank: 26,
  },
]

const Education = () => <ListItem data={education} />

export default Education

import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Education from "../components/education"
import SelectRouter from "./selectRouter"

const EducationPage = () => {
  return (
    <Layout>
      <SEO
        title="Top YouTube Education Channels"
        description="youtuber.life brings you top youtube Education channels"
      />
      <SelectRouter />
      <Education />
    </Layout>
  )
}
export default EducationPage
